.portfolio-container {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.portfolio-header-container {
  margin-top: 100px;
}

html body div#root div.ui.container.portfolio-container div.ui.center.aligned.container.portfolio-header-container h2.ui.header.portfolio-header {
  font-family: Bold;
}

.portfolio-intro {
  margin-top: 75px;
  font-size: 16px;
}

.portfolio-intro p {
  text-align: justify;
  text-align-last: center;
  text-indent: 15px;
  font-family: Regular;
}
