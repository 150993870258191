.footer-container {
  background-color: #E63946;
  border-top: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 -3px 2px 0 rgba(34,36,38,.15);
}

.footer-logo-container {
  display: flex!important;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.footer-heading {
  text-align: center;
  padding-bottom: 25px;
  padding-top: 10px;
  color: #F1FAEE;
  font-family: Bold;
}

.footer-link-container {
  display: flex!important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.footer-links, .footer-social-media-icon {
  color: #F1FAEE;
  transition: 0.2s;
}

.footer-links {
  padding-bottom: 10px;
  font-family: Regular;
}

.footer-icon-container {
  display: flex!important;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.footer-links:hover, .footer-social-media-icon:hover {
  color: #DBE2D9;
  transition: 0.2s;
}

.footer-copyright {
  color: #F1FAEE;
  padding-top: 10px;
  font-size: 9px;
  font-family: Bold;
}

.footer-stripe {
  background-color: #CC3340;
  border: 1px solid rgba(34,36,38,.15);
  width: 100%;
  height: 50px;
}

@media screen and (max-width: 600px) {
  .footer-stripe {
    border: none;
  }
}
