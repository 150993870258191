html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.commissions-status-container h3.ui.header.commissions-status-header,
html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.send-email-container a button.ui.button.send-email-button {
  font-family: Regular;
}

html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.commissions-header-container h2.ui.header.commissions-header,
html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.commissions-status-container h3.ui.header.commissions-status,
html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.pricing-header-container h3.ui.header.pricing-header,
html body div#root div.ui.container.commissions-container div.ui.container.google-form-container h3.ui.header.commissions-status-header,
html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.google-form-header-container h3.ui.header.google-form-header {
  font-family: Bold;
}

.commissions-container {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.commissions-header-container {
  margin-top: 100px;
}

.send-email-container {
  margin-top: 75px;
}

.commissions-intro, .pricing-intro, .google-form-intro {
  margin-top: 75px;
  font-size: 16px;
}

.contact-intro {
  margin-top: 25px;
  font-size: 16px;
}

.commissions-intro p, .pricing-intro p, .google-form-intro p, .contact-intro p {
  text-align: justify;
  text-align-last: center;
  font-family: Regular;
}

.commissions-intro p, .contact-intro p {
  text-shadow: 15px;
}

html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.send-email-container a button.ui.button.send-email-button {
  background: #E63946;
  color: #F1FAEE;
  transition: 0.2s;
}

html body div#root div.ui.container.commissions-container div.ui.center.aligned.container.send-email-container a button.ui.button.send-email-button:hover {
  background: #C0333F;
  color: #DBE2D9;
  transition: 0.2s;
}

.commissions-status-container {
  display: flex!important;
  justify-content: center;
  align-items: baseline;
  margin-top: 70px;
}

.commissions-status-header a {
  margin: 0 8px 0 8px;
  color: #E63946;
  transition: 0.2s;
}

.commissions-status-header a:hover {
  margin: 0 8px 0 8px;
  color: #C0333F;
  transition: 0.2s;
}

.commissions-status {
  color: #E63946!important;
  margin-left: 8px!important;
}

.google-form-container {
  margin: 75px 0 100px 0;
}
