.keyboard-image-carousel-container {
  margin-top: 50px;
}

div.carousel:nth-child(1) {
  -webkit-box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.36); 
  box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.36);
}

.carousel .slide img {
  width: auto!important;
  height: 635px;
}

@media screen and (max-width: 600px) {
  .carousel .slide img {
    height: 180px;
  }
}

@media screen and (max-width: 600px) {
  li.thumb {
    width: 62px!important;
  }
}
