.bulleted > div, .ordered > div {
  font-family: Regular;
}

html body div#root div.ui.container.commissions-container div.ui.container.commissions-info-container div.commission-section h4.ui.header.commission-section-header {
  font-family: Bold;
}

.commissions-info-container {
  display: flex!important;
  justify-content: center;
  align-items: center;
  margin: 75px 0 75px 0;
}

.commission-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 293px;
}

@media screen and (max-width: 600px) {
  .commissions-info-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .commission-section {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .commission-section:last-child {
    margin-top: 25px;
  }
}
