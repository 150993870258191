.contact-info-container {
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.contact-info-list {
  margin-top: 62px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-info-list a, div.item:nth-child(4) > div:nth-child(2) {
  color: rgba(0,0,0,.87);
  transition: 0.2s;
  font-family: Regular;
}

.contact-info-list a:hover {
  color: rgb(0, 0, 0);
  transition: 0.2s;
}
