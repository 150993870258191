.keyboard-breadcrumb {
  font-family: Regular;
}

html body div#root div.ui.container.keyboard-container div.ui.container.breadcrumb-container div.ui.breadcrumb div.active.section,
html body div#root div.ui.container.keyboard-container div.ui.center.aligned.container.keyboard-header-container h2.ui.header.keyboard-header,
.keyboard-video-caption {
  font-family: Bold;
}

.keyboard-container {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.keyboard-loading-icon-container {
  height: 69em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  color: #E63946;
}

.breadcrumb-container {
  margin-top: 25px;
}

.ui.breadcrumb a {
  color: rgba(0,0,0,.87);
  transition: 0.2s;
} 

.ui.breadcrumb a:hover {
  color: rgb(0, 0, 0);
  transition: 0.2s;
}

.keyboard-header-container {
  margin-top: 50px;
}

.keyboard-description {
  margin-top: 75px;
  font-size: 16px;
}

.keyboard-video-container {
  display: flex!important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.keyboard-video {
  margin-top: 25px;
  -webkit-box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.36); 
  box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.36);
}

.keyboard-video-caption {
  margin-top: 75px;
  color: #E63946
}

@media screen and (max-width: 600px) {
  .keyboard-loading-icon-container {
    height: 50em;
  }
}

@media screen and (max-width: 600px) {
  .keyboard-video {
    width: 100%!important;
  }
}
