@font-face {
  font-family: Regular;
  src: url(../../fonts/Roboto\ Mono\ Light\ for\ Powerline.ttf);
}

@font-face {
  font-family: Italic;
  src: url(../../fonts/Roboto\ Mono\ Light\ Italic\ for\ Powerline.ttf);
}

@font-face {
  font-family: Bold;
  src: url(../../fonts/Roboto\ Mono\ Medium\ for\ Powerline.ttf);
}

.home-intro p, .home-keyboard-importance p {
  font-family: Regular;
}

html body div#root div.ui.container.home-container div.ui.center.aligned.container.home-header-container h2.ui.header.home-header, .home-video-caption {
  font-family: Bold;
}

body {
  background-color: #F1FAEE;
}

.home-container {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.home-header-container {
  margin-top: 100px;
}

.home-intro {
  margin-top: 75px;
  font-size: 16px;
}

.home-intro p {
  text-align: justify;
  text-align-last: center;
  text-indent: 15px;
}

.home-keyboard-importance {
  margin-top: 75px;
  font-size: 16px;
  text-align: justify!important;
  text-align-last: center;
}

.home-video-container {
  display: flex!important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-keyboard-video {
  margin: 75px 0 25px 0;
  -webkit-box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.36); 
  box-shadow: 0px 0px 32px 10px rgba(0,0,0,0.36);
}

.home-video-caption {
  margin-bottom: 100px;
  color: #E63946
}

@media screen and (max-width: 600px) {
  .home-keyboard-video {
    width: 100%!important;
  }
}
