.keyboard-tile-one {
  font-family: Regular;
  width: 100%;
  height: 250px;
  padding: 10px 40px 10px 40px;
  color: #F1FAEE;
  text-align: justify;
  text-align-last: center;
  background-color: #A8DADC;
}

.keyboard-tile-one h3 {
  margin-top: 25px;
}

.keyboard-tile-font, .ui.list .list > .item .header, .ui.list > .item .header {
  font-family: Bold;
}

.keyboard-tile-container {
  margin: 75px 0 100px 0;
}

.keyboard-spec-container {
  display: flex!important;
  justify-content: space-between;
  align-items: center;
}

.keyboard-spec-item {
  width: 30%!important;
}

.ui.list .list > .item .header, .ui.list > .item .header {
  color: #F1FAEE;
  text-decoration: underline;
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
  .keyboard-tile-one {
    height: auto;
    text-align: left;
    text-align-last: left;
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 991px) {
  .keyboard-spec-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .keyboard-spec-container {
    margin: 0!important;
  }
}

@media screen and (max-width: 991px) {
  .keyboard-spec-item {
    width: 100%!important;
  }
}

@media screen and (max-width: 767px) {
  html body div#root div.ui.container div.ui.container.keyboard-tile-container div.keyboard-tile-one div.ui.container.keyboard-spec-container {
    margin: 0!important;
  }
}
