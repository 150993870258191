.home-tile-container {
  display: flex!important;
  margin-top: 75px;
}
  
.tile-one, .tile-two, .tile-three {
  width: 33.33%;
  height: 175px;
  padding: 10px 40px 10px 40px;
  color: #F1FAEE;
  text-align: justify;
  text-align-last: center;
}

.tile-one h3, .tile-two h3, .tile-three h3 {
  margin-top: 25px;
  font-family: Bold;
}

.tile-one span, .tile-two span, .tile-three span {
  font-family: Regular;
  font-size: 15px;
}

.tile-one {
  background-color: #A8DADC;
}

.tile-two {
  background-color: #457B9D;
}
  
.tile-three {
  background-color: #1D3557;
}

@media screen and (max-width: 991px) {
  .home-tile-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .tile-one, .tile-two, .tile-three {
    width: 100%;
    padding: 0px 40px 0px 40px;
  }
}

@media screen and (max-width: 400px) {
  .tile-one span, .tile-two span, .tile-three span {
    font-size: 12px;
  }
}
