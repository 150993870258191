.portfolio-loading-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 250px 0 250px 0;
}

.loading-icon {
  color: #E63946;
}

.portfolio-list-container {
  display: flex!important;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 75px 0 100px 0;
}

.portfolio-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.portfolio-card-with-space:last-child {
  margin-right: 414px;
}

.portfolio-card-heading-container {
  width: 100%;
  text-align: center;
  background-color: #E63946;
  padding: 10px 0 10px 0;
}

.portfolio-card-heading {
  color: #F1FAEE;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
  font-family: Bold;
}

.portfolio-card img {
  transition: 0.2s;
}

.portfolio-card:hover img {
  -webkit-filter: blur(4px);
  filter: blur(2px);
  transition: 0.2s;
}

.portfolio-card:hover .portfolio-card-heading-container .portfolio-card-heading {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

@media screen and (max-width: 600px) {
  .portfolio-loading-icon-container {
    margin: 100px 0 250px 0;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-list-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 655px) {
  .portfolio-list-container {
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .portfolio-card {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .portfolio-card-with-space:last-child {
    margin-right: 0!important;
  }
}

@media screen and (max-width: 1199px) {
  .portfolio-card-with-space:last-child {
    margin-right: 316px;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-card:not(:last-child) {
    padding-bottom: 40px;
  }
}
