html body div#root div.ui.container.not-found-container div.ui.center.aligned.container.not-found-navigation-container a.ui.large.button {
  font-family: Regular;
}

html body div#root div.ui.container.not-found-container div.ui.center.aligned.container.not-found-header-container h2.ui.header.not-found-header,
.not-found-url-container span {
  font-family: Bold;
}

.not-found-container {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.not-found-header-container {
  margin: 100px 0 75px 0;
}

.not-found-url-container {
  margin-bottom: 70px;
  background-color: #E63946;
  padding: 5px;
  color: #F1FAEE;
  font-weight: bold;
  border-radius: .28571429rem;
  box-shadow: 0px 0px 12px -2px rgba(0,0,0,.36)
}

i.icons .icon:first-child {
  margin-right: 0;
}

.not-found-icon {
  color: #1D3557;
}

.not-found-keyboard-icon {
  color: #A8DADC;
}

.not-found-navigation-container {
  display: flex!important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 75px 0 100px 0;
}

.not-found-navigation-container span {
  margin-bottom: 75px;
  font-family: Regular;
}

html body div#root div.ui.container.not-found-container div.ui.center.aligned.container.not-found-navigation-container a.ui.large.button.back-button {
  background: #E63946;
  color: #F1FAEE;
  transition: 0.2s;
}

html body div#root div.ui.container.not-found-container div.ui.center.aligned.container.not-found-navigation-container a.ui.large.button.back-button:hover {
  background: #C0333F;
  color: #DBE2D9;
  transition: 0.2s;
}
