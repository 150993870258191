.right > a:nth-child(1), .right > a:nth-child(2), .right > a:nth-child(3), .right > a:nth-child(4) {
  font-family: Regular;
}

.ui.menu {
  background-color: #F1FAEE;
  margin: 0;
  border: none;
  box-shadow: none;
}

.fixedMenuStyle {
  background-color: '#fff';
  border: '1px solid #ddd';
  box-shadow: '0px 3px 5px rgba(0, 0, 0, 0.2)';
}

.menuStyle {
  border: 'none';
  border-radius: 0;
  box-shadow: 'none';
  margin-bottom: '1em';
  margin-top: '4em';
  transition: 'box-shadow 0.5s ease, padding 0.5s ease';
}

.ui.menu .item > i.icon {
  margin: 0;
}

.nav-social-media-icon-container {
  display: flex!important;
}

.nav-social-media-icon {
  color: #E63946;
  transition: 0.2s;
}

.nav-social-media-icon:hover {
  color: #C0333F;
  transition: 0.2s;
}

.nav-stripe {
  background-color: #E63946;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: 0 3px 2px 0 rgba(34,36,38,.15);
  width: 100%;
  height: 5px;
}

@media screen and (max-width: 600px) {
  .ui.menu .item {
    padding: .43857143em 1.14285714em;
  }
}

@media screen and (max-width: 767px) {
  .nav-social-media-icon-container {
    display: none!important;
  }
}
